import tw from "twin.macro"
import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Card from "../components/card"

const Wrapper = tw.div`
grid gap-4 grid-cols-2 md:grid-cols-4 lg:grid-cols-6
`

const Index = (props) => {
  const episodes = props.data.allFeedHotelMatze.edges
  return (
    <Layout>
      <SEO title="Welcome" />
      <Wrapper>
        {episodes &&
          episodes.map(({ node }, index) => (
            <Card {...node} key={index} />
          ))}
      </Wrapper>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query RootIndexQuery {
    allFeedHotelMatze {
      edges {
        node {
          title
          link
          content
          itunes {
            duration
            image {
              attrs {
                href
              }
            }
          }
          enclosure {
            url
            type
          }
        }
      }
    }
  }
`
