import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import PlayerContext from "../contexts/player"

// const Wrapper = tw.button`
//     border shadow-md p-4 rounded bg-gradient-to-r from-blue-800 to-blue-700 text-white
// `
const activeStyles = tw`bg-blue-500`

const Card = props => {
    const {episode, setEpisode} = React.useContext(PlayerContext)
    const title = props.title
    const url = props.enclosure.url
    const duration = props.itunes.duration
    const cover = props.itunes.image.attrs.href 
    const active = episode && episode === url
    return (
        <button 
            onClick={() => setEpisode(url)}
            css={[tw`border shadow-md  rounded bg-blue-800 text-white flex flex-col justify-between`, active && activeStyles]}
        >
            <div>
                <img src={cover} alt={title} />
                <span tw="text-sm px-2 py-1">
                    {title}
                </span>
            </div>
            <div tw="text-sm px-2 py-1">
                {duration}
            </div>
        </button>
    )
}

Card.propTypes = {
    title: PropTypes.string.isRequired,
}

export default Card
